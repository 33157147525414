const prescription = {
  right: [
    {
      title: 'Esférico OD',
      action: `orders/setSphericalRightEye`,
      range: [-40, 40],
      id: 'spherical',
      has_negative: true
    },
    {
      title: 'Cilíndrico OD',
      action: `orders/setCylindricalRightEye`,
      range: [-99, 99],
      id: 'cylindricalRight',
      has_negative: true
    },
    {
      title: 'Eixo OD',
      action: `orders/setAxisRightEye`,
      range: [0, 180],
      id: 'axisRight',
      has_negative: false
    },
    {
      title: 'Distância Naso Pupilar OD',
      action: `orders/setInterPupillaryDistanceRightEye`,
      range: [18, 45],
      id: 'interPupillaryDistanceRight',
      has_negative: true
    },
    {
      title: 'Altura OD',
      action: `orders/seFittingtHeightRightEye`,
      range: [10, 40],
      id: 'fittingHeightRight',
      has_negative: false
    },
    {
      title: 'Prisma Horizontal OD',
      action: `orders/setHorizontalPrismAxisValueRightEye`,
      range: [0, 180],
      id: 'horizontalPrismAxisRight',
      has_negative: false
    },
    {
      title: 'Prisma Vertical OD',
      action: `orders/setVerticalPrismAxisValueRightEye`,
      range: [1, 40],
      id: 'verticalPrismAxisRight',
      has_negative: false
    },
    {
      title: 'Curvatura OD',
      action: `orders/setCurvatureRightEye`,
      range: [1, 40],
      id: 'curvatureRight',
      has_negative: false
    },
    {
      title: 'Pantográfico OD',
      action: `orders/setPantographicRightEye`,
      range: [1, 40],
      id: 'pantographicRight',
      has_negative: false
    },
    {
      title: 'Eyecode OD',
      action: `orders/setEyecodeRightEye`,
      range: [1, 40],
      id: 'eyecodeRight',
      has_negative: false
    },
    {
      title: 'CVP OD',
      action: `orders/setCvpRightEye`,
      range: [1, 40],
      id: 'cvpRight',
      has_negative: false
    }
  ],
  left: [
    {
      title: 'Esférico OE',
      action: `orders/setSphericalLeftEye`,
      range: [-40, 40],
      id: 'sphericalLeft',
      has_negative: true
    },
    {
      title: 'Cilíndrico OE',
      action: `orders/setCylindricalLeftEye`,
      range: [-99, 99],
      id: 'cylindricalLeft',
      has_negative: true
    },
    {
      title: 'Eixo OE',
      action: `orders/setAxisLeftEye`,
      range: [0, 180],
      id: 'axisLeft',
      has_negative: false
    },
    {
      title: 'Distância Naso Pupilar OE',
      action: `orders/setInterPupillaryDistanceLeftEye`,
      range: [18, 45],
      id: 'interPupillaryDistanceLeft',
      has_negative: true
    },
    {
      title: 'Altura OE',
      action: `orders/seFittingtHeightLeftEye`,
      range: [10, 40],
      id: 'fittingHeightLeft',
      has_negative: false
    },
    {
      title: 'Prisma Horizontal OE',
      action: `orders/setHorizontalPrismAxisValueLeftEye`,
      range: [0, 180],
      id: 'horizontalPrismAxisLeft',
      has_negative: false
    },
    {
      title: 'Prisma Vertical OE',
      action: `orders/setVerticalPrismAxisValueLeftEye`,
      range: [1, 40],
      id: 'verticalPrismAxisLeft',
      has_negative: false
    },
    {
      title: 'Curvatura OE',
      action: `orders/setCurvatureLeftEye`,
      range: [1, 40],
      id: 'curvatureLeft',
      has_negative: false
    },
    {
      title: 'Pantográfico OE',
      action: `orders/setPantographicLeftEye`,
      range: [1, 40],
      id: 'pantographicLeft',
      has_negative: false
    },
    {
      title: 'Eyecode OE',
      action: `orders/setEyecodeLeftEye`,
      range: [1, 40],
      id: 'eyecodeLeft',
      has_negative: false
    },
    {
      title: 'CVP OE',
      action: `orders/setCvpLeftEye`,
      range: [1, 40],
      id: 'cvpRight',
      has_negative: false
    }
  ]
}

export default prescription
