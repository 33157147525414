<template>
  <div>
    <div style="min-height: 5000px">
      <ProductPanel />
      <ProductType />
      <ProductManufacturer v-if="type && !manufacturer && !manual" />
      <ProductTechnology v-if="manufacturer && !technology" />
      <ProductColor v-if="!color && technology" />
      <ProductIndex v-if="indexes.length > 0 && selectedColor >= 0 && !index" />
      <ProductManufacturerManual v-if="manual" />
      <ProductList v-if="index && !manual" />
      <ProductTotal v-if="index && product && !manual" :total="total" />
      <ProductTreatment
        v-if="!treatment && product && typeId !== 5 && !manual"
      />
      <ProductFrame />
      <ProductPrescriptionPrismChooser v-if="order.hoop_id" />
      <ProductPrescription
        eye="Right"
        title="Olho Direito"
        :eyeData="eyedata.right"
        key="re"
        color="blue"
        v-if="order.hoop_id"
      />
      <ProductPrescription
        eye="Left"
        title="Olho Esquerdo"
        :eyeData="eyedata.left"
        key="le"
        color="red"
        v-if="order.hoop_id"
      />
      <ProductCustomer v-if="type && technology && index && product" />
      <ProductDoctor v-if="type && technology && index && product" />
      <LensesCarroussel v-if="type && technology && index && product" />
      <ProductFrameDetails v-if="type && technology && index && product" />

      <div v-if="type && technology && index && product">
        <b-row class="mt-5">
          <b-col lg="4" class="pb-2"
            ><b-button variant="primary" block size="lg" @click="saveOrder"
              >SALVAR</b-button
            ></b-col
          >
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import EyeData from '../../../core/services/dummy_data/prescription'
import EyeDataSimple from '../../../core/services/dummy_data/prescriptionsimple'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'NewOrderProduct',

  data: () => ({
    loader: false,
    overlay: false,
    has_color: false,
    manual: false
  }),

  watch: {
    loading(val) {
      if (val) {
        this.loader = this.$loading.show({
          delay: 400,
          spinnerColor: 'indigo-14',
          backgroundColor: 'white',
          message: '<span class="text-indigo-14">procurando produtos...</span>'
        })
      }
      if (!val) {
        this.loader.hide()
      }
    }
  },

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder' }),
    ...mapGetters('menus', {
      colors: 'getColors',
      loading: 'getLoader',
      apis: 'getApis',
      products: 'getProducts',
      indexes: 'getIndexes',
      treatments: 'getTreatments',
      hoops: 'getHoops'
    }),

    eyedata() {
      if (this.typeId === 5) {
        return EyeDataSimple
      }

      return EyeData
    },

    selectedColor() {
      return this.order.color_id
    },

    manufacturer() {
      return !!this.order.manufacturer_id
    },

    typeId() {
      return this.order.type_id
    },

    type() {
      return !!this.order.type_id
    },

    technology() {
      return !!this.order.technology_id
    },

    index() {
      return !!this.order.index_id
    },

    color() {
      return !!this.order.color_id
    },

    treatment() {
      return !!this.order.treatment_id
    },

    hoop() {
      return !!this.order.hoop_id
    },

    product() {
      return !!this.order.product_id
    },

    total() {
      let total = 0
      const { product_id, treatment_id, hoop_id } = this.order

      const product = this.products.filter(
        (item) => item.value === product_id
      )[0]

      const treatment = this.treatments
        .flat()
        .filter((item) => item.value === treatment_id)[0]

      if (hoop_id) {
        const hoop = this.hoops
          .flat()
          .filter((item) => item.value === hoop_id)[0]

        total = total + hoop.price
      }

      if (product) {
        total = total + product.price
      }

      if (treatment) {
        total = total + treatment.price
      }

      return this.currency(total)
    }
  },

  methods: {
    ...mapActions('menus', ['fetchApis', 'fetchManufacturer']),
    ...mapActions('orders', ['setSendTo']),
    ...mapActions('menus', ['fetchType']),

    showColors(val) {
      if (val.length > 0) {
        const colors = val.map((item) => ({
          label: item.name,
          value: item.id,
          price: item.price
        }))
        this.$store.commit('menus/SET_COLOR', _.chunk(colors, 2))
        this.has_color = true
      } else {
        this.has_color = false
      }
    },

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },

    saveOrder() {
      this.$q
        .dialog({
          title: 'Enviar pedido',
          message: 'Deseja enviar pedido para algum laboratório?',
          options: {
            type: 'radio',
            model: 'opt1',
            items: this.apis
          },
          ok: {
            color: 'primary',
            label: 'Sim Enviar',
            tabindex: 0
          },
          cancel: {
            color: 'negative',
            label: 'Só salvar certificado',
            tabindex: 0
          },
          persistent: true
        })
        .onOk((data) => {
          this.setSendTo(data)
          this.save()
        })
        .onCancel(() => this.save())
    },

    async save() {
      let loader = this.$loading.show({
        color: '#F64E60'
      })

      this.$axios
        .post('/orders', this.order)
        .then(({ data }) => {
          this.$store.commit('orders/PUSH_ORDER', data)
          this.$q.notify({
            color: 'indigo-14',
            avatar: '/favicon.png',
            position: 'top',
            message: 'Pedido enviado com sucesso!',
            timeout: 4000,
            onDismiss: () => this.$router.push('/dashboard'),
            actions: [
              {
                label: 'Ok',
                handler: () => this.$router.push('/dashboard')
              }
            ]
          })
        })
        .finally(() => loader.hide())
    },

    reset() {
      window.location.reload()
    }
  },

  mounted() {
    this.fetchManufacturer()
    if (this.$route.query.manual == 1) {
      this.manual = true
      this.fetchType({ params: { all: 1 } })
    } else {
      this.manual = false
    }
  }
}
</script>
